/**
 * Share Button
 * @description : Functionality to share the page
 */

(function () {
    var shareButtonEls = document.querySelectorAll('.js-share-button');

    if (shareButtonEls.length) {

        var modalEl = document.querySelector('.js-share-modal');
        var modalContentEl = modalEl.querySelector('.js-share-modal__content');
        var modalHeadingEl = modalEl.querySelector('.js-share-modal__heading');
        var modalCloseEl = modalEl.querySelector('.js-share-modal__close');
        var modalUrlValueEl = modalEl.querySelector('.js-share-modal__url-value');
        var modalPlatformLinkEls = modalEl.querySelectorAll('.js-share-modal__platform-link');

        for (var i = 0; i < shareButtonEls.length; i++) {
            (function (shareButtonEl) {
                shareButtonEl.addEventListener('click', function () {

                    var sharingTitle = shareButtonEl.getAttribute('data-title') ||
                        document.querySelector('meta[property="og:title"]').content ||
                        document.title;

                    var sharingUrl = shareButtonEl.getAttribute('data-url') ||
                        document.querySelector('meta[property="og:url"]').content ||
                        document.location;

                    var shareModalHeading = shareButtonEl.getAttribute('data-modal-heading') ||
                        'Share this page';

                    var isMobileSizeScreen = window.matchMedia(`(max-width: ${window.breakpoints['md']}px)`).matches;

                    if (navigator.share && isMobileSizeScreen) {
                        // Native sharing controls

                        navigator.share({
                            title: sharingTitle,
                            url: sharingUrl
                        }).then(() => {
                            console.log('Thanks for sharing!');
                        }).catch(console.error);

                    } else {
                        // Fallback sharing controls

                        if (!modalEl.hasAttribute('hidden')) {
                            modalEl.setAttribute('hidden', '');
                            return;
                        }

                        modalHeadingEl.textContent = shareModalHeading;
                        modalUrlValueEl.value = sharingUrl;

                        for (var i = 0; i < modalPlatformLinkEls.length; i++) {
                            var modalPlatformLinkEl = modalPlatformLinkEls[i];
                            modalPlatformLinkEl.target = modalPlatformLinkEl.getAttribute('data-target');
                            modalPlatformLinkEl.href = modalPlatformLinkEl.getAttribute('data-base-href') + encodeURIComponent(sharingUrl);
                        }

                        modalEl.removeAttribute('hidden');
                    }
                });
            })(shareButtonEls[i]);
        }

        document.body.addEventListener('mousedown', function (e) {
            if (modalContentEl.contains(e.target)) {
                return;
            }
            for (var i = 0; i < shareButtonEls.length; i++) {
                if (shareButtonEls[i].contains(e.target)) {
                    return;
                }
            }
            modalEl.setAttribute('hidden', '');
        });

        modalCloseEl.addEventListener('click', function () {
            modalEl.setAttribute('hidden', '');
        });

    }

})();
